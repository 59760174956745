<template>
    <div>
      <div class="card query">
        <input type="text" placeholder="用户名" v-model="code" />
        <input type="text" placeholder="姓名" v-model="name" />
        <input type="text" placeholder="手机号" v-model="mobile" />
        <!-- <input type="date" placeholder="更新日期" v-model="date" /> -->
  
        <div class="button-group">
          <button class="primary-button" @click="query">查询</button>
          <button class="reset-button" @click="reset">重置</button>
        </div>
  
        <button class="add" @click="showAddDialog">
          新增
        </button>
      </div>
  
      <div style="padding-top: 30px"></div>
  
      <div class="card">
        <a-skeleton v-if="loading" />
  
        <div v-else>
          <a-empty v-if="list.length === 0" />
  
          <table v-else class="table">
            <tr class="header">
              <td>用户名</td>
              <td>姓名</td>
              <td>手机号</td>
              <td>创建时间</td>
              <td class="center">操作</td>
            </tr>
  
            <tr v-for="item in list" :key="item.id" class="row">
              <td>{{ item.code }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.mobile }}</td>
              <td>{{ item.createAt }}</td>
              <td class="control center">
                <span @click="edit(item)">编辑</span>
                <span
                  style="
                    margin: 0 4px;
                    height: 1em;
                    width: 2px;
                    background-color: #0667fd;
                  "
                ></span>
                <span @click="link(item)">关联角色</span>
                <span
                  style="
                    margin: 0 4px;
                    height: 1em;
                    width: 2px;
                    background-color: #0667fd;
                  "
                ></span>
                <span @click="removeItem(item)">删除</span>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <a-modal
        title="新增"
        :visible="addDialogShow"
        :footer="null"
        @cancel="addDialogShow = false"
        >
        <div style="margin-bottom: 16px">
            <div class="label">用户名</div>
            <a-input style="margin-bottom: 10px" v-model="addDetail.code"/>
            <div style="padding-top: 12px"></div>
            <div class="label">密码</div>
            <a-input style="margin-bottom: 10px" v-model="addDetail.password"/>
            <div style="padding-top: 12px"></div>
            <div class="label">姓名</div>
            <a-input style="margin-bottom: 10px" v-model="addDetail.name"/>
            <div style="padding-top: 12px"></div>
            <div class="label">手机号</div>
            <a-input v-model="addDetail.mobile"/>
        </div>

        <div class="right">
            <a-space>
            <a-button @click="addDialogShow = false">取消</a-button>
            <a-button type="primary" @click="addUser" :loading="btnLoading">保存</a-button>
            </a-space>
        </div>
      </a-modal>

      <a-modal
        title="编辑"
        :visible="editDialogShow"
        :footer="null"
        @cancel="editDialogShow = false"
        >
        <div style="margin-bottom: 16px">
            <div class="label">密码</div>
            <a-input style="margin-bottom: 10px" v-model="editDetail.password"/>
            <div style="padding-top: 12px"></div>
            <div class="label">姓名</div>
            <a-input style="margin-bottom: 10px" v-model="editDetail.name"/>
            <div style="padding-top: 12px"></div>
            <div class="label">手机号</div>
            <a-input v-model="editDetail.mobile"/>
        </div>

        <div class="right">
            <a-space>
            <a-button @click="editDialogShow = false">取消</a-button>
            <a-button type="primary" @click="editUser" :loading="btnLoading">保存</a-button>
            </a-space>
        </div>
      </a-modal>

      <a-modal
        title="关联角色"
        :visible="linkDialogShow"
        :footer="null"
        @cancel="linkDialogShow = false"
        >
        <div style="margin-bottom: 16px">
            <div class="label">角色</div>
            <a-select
                mode="multiple"
                v-model="editDetail.roles"
                style="width: 100%"
                placeholder="请选择"
            >
                <a-select-option v-for="i in roleList" :key="i.id">
                {{ i.name }}
                </a-select-option>
            </a-select>
        </div>

        <div class="right">
            <a-space>
            <a-button @click="linkDialogShow = false">取消</a-button>
            <a-button type="primary" @click="linkUser" :loading="btnLoading">保存</a-button>
            </a-space>
        </div>
      </a-modal>
    </div>
  </template>
  
  <script>
  function fetchUserList(params) {
    return request({
        url: '/user/list',
        params
    })
  }
  function fetchRoleList(params) {
    return request({
        url: '/role/list',
        params
    })
  }
  function userAdd(data) {
    return request({
        url: '/user/add',
        method: 'post',
        data
    })
  }
  function userEdit(data) {
    return request({
        url: '/user/update',
        method: 'post',
        data
    })
  }
  function remove(data) {
    return request({
        url: '/user/delete',
        method: 'post',
        data
    })
  }
  
  import request from '@/api/request.js'
  export default {
    data() {
      return {
        userList: [],
        code: "",
        name: "",
        mobile: "",
        loading: false,
        list: [],
        addDialogShow: false,
        btnLoading: false,
        addDetail: {},
        editDialogShow: false,
        editDetail: {},
        linkDialogShow: false,
        roleList: []
      };
    },
  
    mounted() {
      this.getList();
      fetchRoleList({
          pageNum: 1,
          pageSize: 999,
        })
          .then((res) => {
            if (Array.isArray(res.list)) {
              this.roleList = Object.freeze(res.list);
            }
          })
    },
  
    methods: {
      getList() {
        this.loading = true;
        fetchUserList({
          pageNum: 1,
          pageSize: 999,
          name: this.name,
          code: this.code,
          mobile: this.mobile,
        })
          .then((res) => {
            if (Array.isArray(res.list)) {
              this.list = Object.freeze(res.list).filter(v=>v.status);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
      query() {
        this.getList();
      },
      reset() {
        this.name = "";
        this.code = "";
        this.mobile = "";
        this.getList();
      },
      showAddDialog() {
        this.addDialogShow = true;
        this.addDetail = {};
      },
      addUser() {
        this.btnLoading = true;
        this.addDetail.password = this.$encrypt.encrypt(this.addDetail.password),
        userAdd(this.addDetail)
        .then((res) => {
          console.log(res)
          this.addDialogShow = false;
          this.getList();
        })
        .finally(() => {
          this.btnLoading = false;
        });
      },
      editUser() {
        this.btnLoading = true;
        this.editDetail.password = this.$encrypt.encrypt(this.editDetail.password),
        userEdit(this.editDetail)
        .then((res) => {
          console.log(res)
          this.editDialogShow = false;
          this.getList();
        })
        .finally(() => {
          this.btnLoading = false;
        });
      },
      linkUser() {
        this.btnLoading = true;
        userEdit(this.editDetail)
        .then((res) => {
          console.log(res)
          this.linkDialogShow = false;
          this.getList();
        })
        .finally(() => {
          this.btnLoading = false;
        });
      },
      edit(item) {
        this.editDialogShow = true;
        this.editDetail = {
            name: item.name,
            code: item.code,
            password: "",
            mobile: item.mobile,
            id: item.id
        }
      },
      link(item) {
        this.linkDialogShow = true;
        this.editDetail = {
            id: item.id,
            roles: item.roles.map(v=>v.id)
        }
      },
      removeItem(item) {
        const that = this;
        this.$confirm({
          title: "是否删除？",
          okText: "是",
          okType: "danger",
          cancelText: "否",
          onOk() {
            remove({ id: item.id }).then(() => {
              that.getList();
            });
          },
        });
      }
    },
  };
  </script>
  
  <style lang="less" scoped>
    .label {
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    color: #9ca4b1;
    margin-bottom: 12px;
  }
  .card {
    background-color: #fff;
    box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
    border-radius: 20px;
    padding: 40px;
  }
  .query {
    display: flex;
    align-items: center;
    gap: 10px;
  
    input {
      width: 177px;
      height: 40px;
      padding: 0 12px;
      background: #f8f9fb !important;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 500;
    }
    input::placeholder {
      font-size: 16px;
      font-weight: 500;
      color: #abb3c0;
    }
    select {
      width: 177px;
      height: 40px;
      padding: 0 12px;
      background: #f8f9fb !important;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 500;
    }
  
    .button-group {
      .primary-button {
        width: 71px;
        height: 38px;
        line-height: 100%;
        color: #fff;
        background: linear-gradient(146deg, #0c99ff 0%, #0560fd 100%);
        box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.32);
        border-radius: 6px 0px 0px 6px;
      }
  
      .reset-button {
        width: 71px;
        height: 38px;
        background: #fff;
        border-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top: 3px solid #eff1f6;
        border-right: 3px solid #eff1f6;
        border-bottom: 3px solid #eff1f6;
        box-sizing: border-box;
      }
    }
  }
  
  .add {
    margin-left: auto;
    width: 156px;
    height: 38px;
    line-height: 100%;
    color: #fff;
    background: linear-gradient(146deg, #0c99ff 0%, #0560fd 100%);
    box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.32);
    border-radius: 6px;
  }
  
  .table {
    width: 100%;
    .header {
      opacity: 0.7;
  
      td {
        padding: 16px;
        background: #ebf2fc;
        font-weight: 400;
        color: #9ca4b1;
        font-size: 16px;
      }
      td:first-child {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
      }
      td:last-child {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
      }
    }
  
    .row {
      border-bottom: 1px solid rgba(207, 220, 238, 0.71);
  
      td {
        padding: 16px;
      }
    }
  }
  
  .status {
    display: flex;
    align-items: center;
    gap: 6px;
    img {
      height: 16px;
    }
  }
  
  .control {
    color: #0667fd;
    cursor: pointer;
  }
  </style>